.thisflex {
    max-width: 1000px;
    margin: auto;
}

.liquidationTwo .pdtNmClmn {
    /* white-space: nowrap; */
}

.liquidationTwo.primeBids .redFlashTmr .multiSellerTimerView h6,
.liquidationTwo.primeBids .redFlashTmr .multiSellerTimerView h6 span {
    color: #f70b0b;
}

.liquidationTwo.primeBids .pdtNmClmn {
    white-space: nowrap;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.beachLiquidation .bidBeach {
    justify-content: start !important;
}
.lctnDialogWrapper {
    max-height: 700px;
}

.amountPop b {
    white-space: nowrap;
}

.cancelButton {
    background-color: #ed4b4b !important;
    padding: 6px 15px !important;
    color: #fff !important;
}

.Auctioneer .datePickerLabel {
    position: absolute;
    z-index: 1;
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 1rem;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
    top: -7px;
    left: 24px;
    background-color: #fff;
    padding: 0px 5px;
}

.Auctioneer .react-datepicker-wrapper input {
    height: 56px;
    margin: 0;
    display: block;
    padding: 10px;
    width: 100%;
}

.Auctioneer .react-datepicker-wrapper {
    position: relative;
}
.cincyCard .auctionCnt .actualClass,
.LotsAuction .actualClass {
    display: flex;
    align-items: center;
}
.beachLiquidation .actualClass {
    display: flex;
}
.LiquidationThree .timeClass,
.Liquidation .timeClass {
    display: block;
}
.timeClass {
    display: flex;
}

.LotsAuction .timeClass {
    font-size: 13px;
    align-items: center;
}

.LotsAuction .timeClass .material-icons-outlined {
    font-size: 18px;
}

.LotsAuction .product-view-right .timeClass {
    font-size: 18px;
}

.LotsAuction .product-view-right .timer-wrapper .timerCnt {
    font-size: 18px;
}

.LotsAuction .timerCnt .actualClass .material-icons-outlined {
    font-size: 18px;
}
.firstContent .name .MuiRating-root .MuiRating-decimal span:first-child {
    top: 36px;
    right: 32px;
}
.liquidationTwo.primeBids .multisellerProductDrawer .sl-pbids.slidView .mssDesc {
    display: none;
}
.feedBck span {
    color: var(--primColor);
}

.feedBck:hover span {
    color: #fff;
}

.card-errors {
    color: red;
    font-size: 12px;
}

.dashboard-tabs .table-responsive button {
    white-space: nowrap;
}

input[type='text'],
input[type='number'],
select:focus,
textarea {
    font-size: 16px;
}

.liquidationDashTabs .MuiTabs-flexContainer {
    justify-content: center;
}

.lots-aq-modal .essentialBox > h4 {
    display: none;
}

.LotsAuction a.ask-question {
    cursor: pointer !important;
}

.LotsAuction .contact-us-data {
    display: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    select:focus,
    textarea:focus,
    input:focus {
        font-size: 16px;
    }

    select,
    textarea,
    input {
        font-size: 16px;
    }
}

@media (max-width: 767px) {
    .LiquidationThree .bidHistory tbody td:last-child {
        text-align: left !important;
    }
}

@media screen and (max-width: 600px) {
    .liquidationTwo.primeBids .table tbody td.amount {
        text-align: left;
    }
    .liquidationDashTabs .MuiTabs-flexContainer {
        flex-wrap: wrap;
    }
}
@media (max-width: 475px) {
    .LiquidationThree .liquidationDashTabs .MuiTab-root {
        width: 33% !important;
    }
}
