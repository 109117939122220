.liquidationTwo .footer-main-standard {
    padding: 0;
    color: var(--footerFontColor);
    background: var(--footerColor);
    font-size: 15px;
    margin-top: 0;
}

.footer-main-standard a {
    color: var(--footerFontColor);
}

.footer-copy-right {
    padding: 10px 30px;
    background: var(--footerColor);
}

.footer-copy-right p {
    margin: 0;
}
.beachLiquidation .footerLinks {
    flex-direction: column;
}
.beachLiquidation.liquidationTwo .footerLinks li:not(:last-child),
.beachLiquidation.liquidationTwo .footerLinks li {
    margin-right: auto;
    margin-left: 0px;
    margin: 5px auto 5px 0px !important;
}
.beachLiquidation.liquidationTwo .footerContent h5 {
    font-size: 18px !important;
    text-align: left;
    font-weight: 600;
}
.beachLiquidation .footerLinks li a {
    font-size: 16px !important;
}
.beachLiquidation .footerLinks li {
    text-align: left;
}
.beachLiquidation.liquidationTwo .ftrDtlCntnr .footerLogo {
    height: 80px;
}
.beachLiquidation .footerContent {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
}
a.cvc-link {
    font-size: 15px;
}

.footer-main-standard-title {
    margin-bottom: 15px;
    color: var(--primColor);
    font-size: 20px;
    position: relative;
    padding-bottom: 15px;
}

.footer-main-standard-newsletter {
    position: relative;
    margin-bottom: 20px;
    margin-top: 15px;
}

.newsletter-wrapper {
    position: relative;
}
.footer-main-standard-newsletter-input {
    width: 100%;
    border: none;
    border-radius: 45px;
    padding: 13px 26px;
    color: #4a4031;
    font-size: 16px;
    border: 1px solid #ddd;
}

.footer-main-standard-newsletter-submit {
    position: absolute;
    top: 0;
    right: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    height: 52px;
    font-size: 14px;
    line-height: 33px;
    text-align: center;
    background: var(--primColor);
    color: #ffffff;
    border: none;
    border-top-right-radius: 45px;
    border-bottom-right-radius: 45px;
    padding: 0px 20px;
}
/* 
.ftrDtlCntnr .footerMisc {
    max-width: initial !important;
} */

.liquidationTwo .ftrDtlCntnr .footerMisc {
    max-width: calc(100% - 250px) !important;
    width: 100%;
}

.footer-main-standard-newsletter-submit {
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    -ms-transition: 0.2s;
    transition: 0.2s;
    line-height: 16px;
}

.footer-main-standard-social-list {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 0px;
    margin-left: -7px;
}

.footer-main-standard-social-list > li {
    display: inline-block;
    margin: 5px 6px;
}

.liquidationTwo .footer-main-standard-social-list > li > a {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: var(--primColor);
    color: #fff;
    opacity: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.footer-main-standard-social-list > li > a > svg {
    fill: #fff;
    width: 70%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s;
}

.liquidationTwo .ftrDtlCntnr {
    text-align: center;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    border-top: 1px solid #e5e5e5;
    padding-top: 35px;
    margin-top: 35px;
}

.liquidationTwo.primeBids .ftrDtlCntnr {
    border-top: none;
}

.liquidationTwo .footer-main-standard-social-list {
    margin: 0;
    margin-bottom: 16px;
    width: 100%;
    text-align: right;
}

.liquidationTwo .ftrDtlCntnr .footerLogo {
    width: 100%;
    height: 50px;
    object-fit: contain;
    margin: auto;
    border-radius: 10px;
    margin-bottom: 15px;
}

.liquidationTwo .footLogoCnt {
    text-align: left;
}

.ftrDtlCntnr .cvc-link {
    color: var(--footerFontColor);
    margin: 10px 0;
    display: inline-flex;
    align-items: center;
}

.ftrDtlCntnr .cvc-link:hover {
    text-decoration: none;
}

.ftrDtlCntnr .cvc-link .material-icons {
    padding-right: 10px;
    color: var(--footerFontColor);
}

.ftrDtlCntnr .fa,
.ftrDtlCntnr .fab,
.ftrDtlCntnr .fal,
.ftrDtlCntnr .far,
.ftrDtlCntnr .fas {
    font-size: inherit;
    margin: 0 auto;
}

.liquidationTwo .footerLinks {
    list-style: none;
    padding: 0;
    margin: 15px 0;
    display: flex;
    justify-content: flex-end;
}

.liquidationTwo .footerLinks li:not(:last-child) {
    margin-right: 45px;
}

.liquidationTwo .footerLinks li a {
    font-size: 15px;
    font-weight: 500;
    /* color: #333; */
}

.liquidationTwo.primeBids footer .copyright,
.liquidationTwo.primeBids .footerLinks li a {
    color: #ffffff;
}
.beachLiquidation.liquidationTwo footer .copyright {
    font-size: 14px;
}
.liquidationTwo footer .copyright {
    font-size: 12px;
    /* color: #6f6f6f; */
}

.liquidationTwo .footerLinks li a {
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
    -webkit-transition: 200ms color ease-in-out;
    -o-transition: 200ms color ease-in-out;
    transition: 200ms color ease-in-out;
    position: relative;
}

.liquidationTwo #change-footer-hov-color a:hover {
    color: #ffffff;
}

.liquidationTwo .footerLinks li a:hover {
    color: var(--primColor);
}
.liquidationTwo.beachLiquidation .footerLinks li a:hover {
    color: #000;
}
.liquidationTwo .footerLinks li a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: -10px;
    content: '';
    display: block;
    height: 3px;
    left: 50%;
    position: absolute;
    background: var(--primColor);
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.liquidationTwo .footerLinks li a:hover:after {
    width: 100%;
    left: 0;
}

.BidAuction footer {
    background: #203c7d;
}
.BidAuction .footerInner {
    display: grid;
    max-width: 1440px;
    grid-template-columns: 2fr 1fr;
    margin: auto;
    padding-block: 75px;
    padding-inline: 30px;
}
.BidAuction .footerInner .footerLeft_Base dl dd.navTitlt {
    font-size: 30px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 30px;
    line-height: normal;
}
.BidAuction .footerInner .footerLeft_Base dl dd a {
    color: #fff;
}
.BidAuction .footerInner .footerLeft_Base dl dd {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 12px;
    line-height: normal;
}
.BidAuction .footerInner .footerLeft_Base {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
    align-items: flex-start;
}
.BidAuction .footerInner .footerRight_Base .socialButton_Base {
    display: flex;
    justify-content: center;
    gap: 15px;
    flex-wrap: wrap;
    margin-top: 35px;
}
.BidAuction .footerInner .footerRight_Base .copyright {
    font-size: 16px;
    font-weight: 400;
    margin-top: 20px;
}
.BidAuction .footerInner .footerRight_Base {
    text-align: center;
    color: #aeb5bf;
}

.BidAuction .footerInner .footerLeft_Base dl dd.secondhalfTit a,
.BidAuction .footerInner .footerLeft_Base dl dd.secondhalfTit {
    font-size: 18px;
    font-weight: 600;
    color: #ed7225;
    margin-bottom: 12px;
    line-height: normal;
}

/* responsive */

@media (max-width: 1025px) {
    .beachLiquidation.liquidationTwo .ftrDtlCntnr {
        justify-content: center;
        flex-wrap: wrap;
    }
}
@media (max-width: 991px) {
    .footerLinks {
        display: none;
    }
    .liquidationTwo .ftrDtlCntnr {
        justify-content: center !important;
    }

    .liquidationTwo .ftrDtlCntnr .footerMisc {
        max-width: unset !important;
        width: 100%;
    }

    .liquidationTwo .footer-main-standard-social-list {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ftrDtlCntnr {
        margin-bottom: 20px !important;
    }

    .liquidationTwo .ftrDtlCntnr .footerMisc {
        height: max-content;
    }

    .liquidationTwo .footerLinks {
        margin-bottom: 20px;
        justify-content: flex-start;
    }
}
@media (max-width: 625px) {
    .beachLiquidation.liquidationTwo .footerContent h5 {
        font-size: 16px !important;
    }
    .beachLiquidation.liquidationTwo .footerContent .footerLinks li a {
        font-size: 14px !important;
    }
}

@media (max-width: 600px) {
    .ftrDtlCntnr .footerLogo {
        width: 100px;
    }
    .beachLiquidation.liquidationTwo .footerContent {
        grid-template-columns: 1fr 1fr;
    }
    .beachLiquidation.liquidationTwo .footerLinks {
        display: block;
    }
    .liquidationTwo .ftrDtlCntnr {
        flex-wrap: wrap;
    }

    .liquidationTwo .footerLinks {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 5px;
    }

    .liquidationTwo .footerLinks li {
        margin: 0;
        text-align: left;
    }

    .liquidationTwo .footerLinks li a {
        font-size: 13px;
    }
    .beachLiquidation.liquidationTwo .ftrDtlCntnr .footerLogo {
        height: 100px;
    }
}

@media (max-width: 500px) {
    .liquidationTwo .ftrDtlCntnr {
        margin-top: 15px;
    }
    .beachLiquidation .ftrDtlCntnr {
        display: block;
    }
}
@media (max-width: 445px) {
    .beachLiquidation.liquidationTwo .footerContent {
        grid-template-columns: 1fr;
    }
}
