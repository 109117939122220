.LotsAuction .mainHeader {
    height: 100px;
    position: sticky;
    top: 0;
    z-index: 1000;
    background: var(--headerColor);
    color: var(--headerFontColor);
    padding: 10px 0;
    border-bottom: none;
    overflow: hidden;
    box-shadow: none;
}

.LotsAuction.musicCollect .mainHeader {
    background: #3407b0;
}

.LotsAuction .mainHeader .headRt ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.LotsAuction .mainHeader .headRt ul li {
    color: inherit;
}

.LotsAuction.localDeals .mainHeader .headRt ul li.hiw {
    display: none;
}

.LotsAuction .mainHeader .headRt ul a {
    color: inherit;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    -webkit-transition: 200ms color ease-in-out;
    -o-transition: 200ms color ease-in-out;
    transition: 200ms color ease-in-out;
    position: relative;
}

.LotsAuction .mainHeader .headRt ul a.active {
    color: black;
    mix-blend-mode: multiply;
}

.LotsAuction.musicCollect .mainHeader .headRt ul a.active {
    color: #fff;
    mix-blend-mode: lighten;
    text-decoration: underline;
}

.LotsAuction .mainHeader .headRt #change-bg-font-color a.active {
    color: #efc486e8;
    mix-blend-mode: normal;
}

.LotsAuction.estate .mainHeader .headRt ul a.active {
    color: #fff;
    mix-blend-mode: initial;
}

.LotsAuction .mainHeader .headRt ul li button {
    color: #646464;
    font-size: 16px;
    font-weight: 600;
    text-transform: initial;
}

.LotsAuction .mobHeader .headRt ul li button {
    min-width: initial !important;
    margin: 0;
    width: 40px;
}

.LotsAuction .mainHeader .headRt ul li button .material-icons {
    padding-left: 10px;
}

.LotsAuction .mainHeader .headRt ul a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: -10px;
    content: '';
    display: block;
    height: 3px;
    left: 50%;
    position: absolute;
    background: inherit;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.LotsAuction .mainHeader .headRt ul a.active:after {
    width: 100%;
    left: 0;
}

.LotsAuction .mainHeader .headRt ul a:hover:after {
    width: 100%;
    left: 0;
}

.LotsAuction .mainHeader .headRt ul li:not(:last-child) {
    margin-right: 25px;
}

.LotsAuction .nav-standard-top-area-search-inner {
    position: relative;
    width: 100%;
}

.LotsAuction .mainHeader .nav-standard-top-area-search-inner {
    margin-left: 20px;
}

.LotsAuction .db-bids-wrapper .nav-standard-top-area-search-input {
    height: 40px;
}

.LotsAuction .nav-standard-top-area-search-input {
    border: 1px solid #ede7e7;
    padding: 4px 15px 4px 15px;
    color: #333;
    width: 100%;
    display: block;
    height: 45px;
    margin-right: 15px;
    background: #fff;
    border-radius: 0;
}

.LotsAuction .nav-standard-top-area-search-input::placeholder {
    color: #333;
}

.LotsAuction .nav-standard-top-area-search-input:focus {
    outline: none;
    border-color: #ddd;
}

.LotsAuction
    .db-bids-wrapper
    .nav-standard-top-area-search-inner
    .nav-standard-top-area-search-sumbit {
    height: 40px;
}

.LotsAuction .nav-standard-top-area-search-sumbit,
.LotsAuction .nav-standard-top-area-search-clear {
    width: 45px;
    height: 45px;
    padding: 0 7px;
    line-height: 30px;
    display: inline-flex;
    text-align: left;
    position: absolute;
    top: 0;
    right: 15px;
    color: #fff;
    background: var(--secColor);
    border: none;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}
.LotsAuction .subLotSearch {
    right: 20px;
}
.LotsAuction .nav-standard-top-area-search-clear {
    right: 5px;
    border-radius: 0 8px 8px 0;
}

.LotsAuction .mainHeader .headLt {
    width: 100%;
    max-width: 250px;
    margin-right: 10px;
}

.LotsAuction .mainHeader .nav-standard-logo {
    width: 100%;
    max-width: 140px;
    height: 80px;
}

.LotsAuction .mainHeader .headLt .headerLogo {
    width: 100%;
    max-width: 100%;
    object-fit: contain;
    object-position: left;
    height: 90px;
    margin-right: 0px;
}

.LotsAuction .headerDrawer .MuiPaper-root.MuiDrawer-paper {
    width: 300px;
    background: #fff;
}

.LotsAuction .headerDrawer .mobShopLocation.MuiListItem-root {
    flex-wrap: wrap;
}

.LotsAuction .headerDrawer .mobShopLocation.MuiListItem-root p {
    font-size: 14px;
    padding-inline-start: 8px;
    margin: 0;
}

.LotsAuction .headerDrawer .mobShopLocation.MuiListItem-root .MuiButtonBase-root {
    width: 100%;
    justify-content: space-between;
}

.LotsAuction .headerDrawer .headerDrawerNavLinks {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: calc(100vh - 100px);
    height: auto;
}

.LotsAuction .headerDrawer .headerDrawerNavLinks hr {
    width: 100%;
}

.LotsAuction .respNav,
.LotsAuction .respNavBtn {
    display: none !important;
}

.LotsAuction .respHeaderSearch .MuiPaper-root {
    padding: 15px;
}

.LotsAuction .respHeaderSearch .nav-standard-top-area-search-input {
    height: 50px;
}

.LotsAuction .respHeaderSearch button {
    height: 100%;
}

.LotsAuction .auctionHasItems {
    padding: 15px 30px;
    background: #f3f7ff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
}

.LotsAuction .auctionHasItems h3 {
    font-size: 18px;
    font-weight: 500;
    color: #000;
    margin-left: 30px;
}

.LotsAuction .auctionHasItems img {
    width: 30px;
    height: auto;
    object-fit: contain;
}

.LotsAuction .auctionHasItems h3:last-child {
    margin-bottom: 0;
}

.LotsAuction .auctionHasItems h3 a {
    color: inherit;
    font-weight: 600;
}

.LotsAuction .mainHeader .nav-standard-top-area-search {
    max-width: calc(100% - 650px);
    width: 100%;
    min-width: 210px;
}
.LotsAuction .mainHeader .become_button {
    color: inherit;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    transition: 200ms color ease-in-out;
    position: relative;
}

.LotsAuction .mainHeader .nav-standard-top-area-search-inner {
    margin-left: 0px;
    display: flex;
    align-items: center;
}

.nav-standard-top-area-search-inner .custom-state-city {
    border: 1px solid #ede7e7;
    border-radius: 8px 0 0 8px;
    width: 140px;
    height: 45px;
    display: flex;
    align-items: center;
    padding: 5px;
}

.LotsAuction .nav-standard-top-area-search-inner .custom-state-city select {
    background: transparent;
}

.nav-standard-top-area-search-inner .custom-state-city select {
    border: none;
}

.LotsAuction .textSliderWrapper {
    background: #000;
    color: #fff;
    text-align: center;
}

/* Responsive */

@media (max-width: 1440px) {
    .LotsAuction.localDeals .mainHeader .headRt ul li:not(:last-child) {
        margin-right: 15px;
    }
}

@media (max-width: 1275px) {
    .LotsAuction .mainHeader .nav-standard-top-area-search {
        max-width: 300px;
        margin: 0px 20px;
        min-width: 160px;
    }
    .LotsAuction .mainHeader .headRt ul li:not(:last-child) {
        margin-right: 15px;
    }
    .LotsAuction .customContainer {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}
@media (max-width: 1120px) {
    .LotsAuction .mainHeader .headLt .headerLogo {
        min-width: 90px;
    }
    .LotsAuction .mainHeader .become_button {
        font-size: 14px;
        padding-left: 7px !important;
        padding-right: 7px !important;
    }
}
@media (max-width: 1024px) {
    .LotsAuction .mainHeader {
        height: 80px;
    }

    .LotsAuction .mainHeader .headRt ul li:not(:last-child) {
        margin-right: 10px;
    }

    .LotsAuction header .headLt img {
        max-width: 180px;
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .LotsAuction .deskNav {
        display: none !important;
    }
    .LotsAuction .respNav {
        display: block !important;
    }
    .LotsAuction .respNavBtn {
        display: inline-flex !important;
        min-width: max-content !important;
        margin-inline-start: 10px !important;
        color: var(--headerFontColor) !important;
    }

    .LotsAuction .headRt ul {
        list-style: none;
        margin: 0;
    }

    .LotsAuction .headRt.respNav .navRespLinks {
        padding-inline-start: 0;
        list-style-type: none;
        flex-wrap: wrap;
        margin: 0;
        margin-top: 15px;
    }

    .LotsAuction .headRt.respNav .MuiListItem-root {
        padding: 12px 20px;
        color: #4a4a4a;
        font-family: var(--FuturaStdMedium);

        /* justify-content: space-between; */
    }

    .LotsAuction .headRt.respNav .MuiListItem-root > div {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .LotsAuction .headRt.respNav .MuiListItem-root span.material-icons {
        padding-inline-end: 15px;
        color: #a6a6a6;
    }

    .LotsAuction header .headRt ul li:not(:last-child) {
        margin-inline-end: 0;
    }

    .LotsAuction .headRt.respNav .navRespLinks a {
        width: 100%;
        text-decoration: none;
        color: #4a4a4a;
        display: flex;
        align-items: center;
        font-family: var(--FuturaStdMedium);
        flex-wrap: wrap;
    }

    .LotsAuction .headRt.respNav .navRespLinks a .hnContact {
        padding-inline-start: 40px;
    }

    .LotsAuction .headRt.respNav .navRespLinks a.active,
    .LotsAuction .headRt.respNav .navRespLinks a.active span {
        color: var(--primColor);
    }

    .LotsAuction .headRt.respNav .naLogoHead {
        padding: 8px 0px 8px 30px;
        padding-inline-start: 20px;
        padding-inline-end: 0;
    }

    .LotsAuction .headRt.respNav .naLogoHead img {
        width: 100px;
        height: 100px;
        object-fit: contain;
    }

    .LotsAuction .headerDrawer .MuiPaper-root.MuiDrawer-paper {
        width: 300px;
        background: #fff;
    }

    .LotsAuction .headerDrawer .mobShopLocation.MuiListItem-root {
        flex-wrap: wrap;
    }

    .LotsAuction .headerDrawer .mobShopLocation.MuiListItem-root p {
        font-size: 14px;
        padding-inline-start: 8px;
        margin: 0;
    }

    .LotsAuction .headerDrawer .mobShopLocation.MuiListItem-root .MuiButtonBase-root {
        width: 100%;
        justify-content: space-between;
    }

    .LotsAuction .headerDrawer .headerDrawerNavLinks {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        min-height: calc(100vh - 100px);
        height: auto;
    }

    .LotsAuction .headerDrawer .headerDrawerNavLinks hr {
        width: 100%;
    }

    .LotsAuction .navCategories {
        padding-inline-start: 0;
        margin: 0;
        padding-top: 10px;
    }

    .LotsAuction .navCategories .navBack {
        color: #8a8a8a;
    }

    .LotsAuction .navCategories .MuiListItem-button {
        width: 100%;
    }
    .LotsAuction .notLoggedLink {
        padding: 10px 20px;
        width: 100%;
    }
    .LotsAuction .headerSearchForm {
        width: 90%;
    }
    .LotsAuction .mainHeader .headLt .headerLogo {
        width: 100px;
        height: 100%;
    }
}

@media (max-width: 600px) {
    .LotsAuction .mainHeader .headLt .headerLogo {
        width: 65px;
        min-width: 65px;
    }
    .LotsAuction .headRt.respNav .naLogoHead img {
        width: 70px;
        height: auto;
    }
    .LotsAuction .textSliderWrapper {
        color: #fff;
    }
    .auctionHasItems {
        height: auto;
        padding: 5px 15px;
    }
    .auctionHasItems h3 {
        font-size: 15px;
        line-height: 24px;
        margin-left: 15px;
    }
}

.header-links-bottom {
    padding: 22px 0;
    border: 1px solid #edeaea;
    background-color: var(--secColor);
}

.header-links-bottom .menu-blinks {
    list-style: none;
    display: flex;
    justify-content: space-between;
    max-width: 980px;
    margin: 0 auto;
}
.LotsAuction .mainHeader .headRt ul a.login-btn,
.LotsAuction .mainHeader .headRt ul a.signup-btn {
    border-radius: 8px;
    background: #f1f6ff;
    color: var(--primColor);
    border: 1px solid #f1f6ff;
    width: 126px;
    height: 36px;
    display: inline-block;
    padding: 10px;
    line-height: 14px;
    margin: 4.5px 0 4.5px 10px;
    text-align: center;
}

.LotsAuction .mainHeader .headRt ul a.login-btn:hover,
.login-btn:focus {
    border-color: #f1f6ff;
}

.LotsAuction .mainHeader .headRt ul a.signup-btn {
    background: var(--primColor);
    color: #fff;
    border: 1px solid var(--primColor);
}

.LotsAuction .mainHeader .headRt ul a.signup-btn:hover,
.LotsAuction .mainHeader .headRt ul a.signup-btn:focus {
    color: var(--primColor);
    background: #fff;
}
.LotsAuction .mainHeader .headRt ul a.signup-btn:after,
.LotsAuction .mainHeader .headRt ul a.login-btn:after {
    display: none;
}

.header-links-bottom .menu-blinks a {
    font-family: 'Poppins';
    font-size: 14px;
    cursor: pointer;
    color: #ffffff;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.0001em;
}

.auction-wrapper .mslDesc .MuiAccordionSummary-root {
    min-height: 34px;
}

.auction-wrapper .mslDesc .MuiAccordionSummary-root h6,
.auction-wrapper .mslDesc .MuiAccordionSummary-content {
    margin: 0;
}

.auction-wrapper .mslDesc .MuiAccordionSummary-expandIcon {
    padding: 5px;
}

@media (max-width: 767px) {
    .LotsAuction .nav-standard-top-area-search-inner .custom-state-city {
        width: 100px;
    }

    .LotsAuction .nav-standard-top-area-search-inner .custom-state-city select {
        font-size: 13px;
    }
    .LotsAuction .nav-standard-top-area-search-inner {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
    }

    .LotsAuction .respHeaderSearch .nav-standard-top-area-search-input {
        height: 45px;
        margin-right: 0;
    }

    .LotsAuction .respHeaderSearch button {
        height: 100%;
        right: 0;
    }
}
